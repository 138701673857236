<template>
    <div class="homeDiv">
        <div class="buttons">
            <div>
                <smallbutton toplabel="Reports" buttonclass="buttonprimary widebutton" @click="gotoreports()" v-if="canAccess('View Reports')"/>
                <smallbutton toplabel="Student Status" buttonclass="buttonprimary widebutton" @click="studentStatus()" v-if="canAccess('View Student Status')"/>
            </div>
            
            <div class="horizFlex">
                <smallbutton :toplabel="instructorClockInLabel" :buttonclass="instructorbuttonclass" v-if="ShowClockInInstructor"
                    @click="instructorInOut"/>
                
                <clockin ref="clockinbutton" buttonclass="buttonprimary widebutton" @refreshafterin="refreshafterClockIn" @offline="handleOffline()"/>
                <clockout  :buttonclass="studentbuttonclass" v-if="!isManualTime" @refresh="afterClockOut()" @offline="handleOffline()"/>
                <!-- <button @click="showtime">t</button> -->
            </div>
            
        </div>
        <div class="leftjustified margin10">
            <linkbutton :toplabel="lookuptogglelabel" @click="togglelookup()"/>
        </div>
        <div class="filter horizFlex wrap " v-on:keyup.enter="filtergrades" v-if="showLookup">
            <p class="filterlabel">Lookup</p>
            <input class="filterfield" type="date" v-model="filter.startDate"/>
            <p class="filterlabel">to</p>
            <input class="filterfield" type="date" v-model="filter.endDate"/>
            
            <select name="" id="" class='filterselect' v-model="filter.locationId" required @change="selectLocation()">
                <option v-for="loc in locationList" :key="loc.id" :value="loc.id" >
                    {{loc.name}}
                </option>
            </select>
            <select name="" id="" class='filterselect' v-model="filter.studentId" required >
                <option v-for="s in studentList" :key="s.id" :value="s.id" >
                    {{s.name}}
                </option>
            </select>
            <select name="" id="" class='filterselect' v-model="filter.instructorId" required v-if="showInstructorList">
                <option v-for="i in instructorList" :key="i.id" :value="i.id" >
                    {{i.name}}
                </option>
            </select>
            <select name="" id="" class='filterselect' v-model="filter.studentGroupId" required v-if="showStudentGroup">
                <option v-for="c in studentGroupList" :key="c.ID" :value="c.ID" >
                    {{c.Name}}
                </option>
            </select>
            <select name="" id="" class='filterselect' v-model="filter.productId" required  v-if="showProductFilter">
                <option v-for="c in productList" :key="c.id" :value="c.id" >
                    {{c.name}}
                </option>
            </select>
            <select name="" id="" class='filterselect' v-model="filter.procedureSequenceId" required >
                <option v-for="c in procedureList" :key="c.id" :value="c.id" >
                    {{c.name}}
                </option>
            </select>

            <smallbutton toplabel="Go" @click="filtergrades" buttonclass="buttonprimary narrowbutton"/>
            <smallbutton toplabel="Reset" buttonclass="buttonprimary narrowbutton" @click="resetfilter"/>
            
            <div class="horizFlex">
                <input class="filterfield" type="checkbox" v-model="filter.includeComplete"/>
                <p class="filterlabel">include completed</p>
                <input class="filterfield" type="checkbox" v-model="filter.includeInactive"/>
                <p class="filterlabel">include removed</p>
            </div>
            
        </div>
        <div class="margin10" v-if="pleaseWait">
                <label class="pleasewait">Please Wait...</label>
        </div>
        <div class="margin10" v-if="oldUnsubmittedCount>0">
                <label class="responsemessage">{{ unsubmittedtext }}</label>
        </div>
        <div class="gradesheets">
            <div :class="gradeheaderclass()" v-if="gradeList.length>0">
                
                <linkbutton class="recordlabel bold" @click="sortBy='name'" toplabel="Student"/>
                <linkbutton class="recordlabel bold" @click="sortBy='date'" toplabel="Date/Time"/>
                <!-- <linkbutton class="recordlabel bold" @click="sortBy='location'" toplabel="Location"/> -->
                <linkbutton class="recordlabel bold" @click="sortBy='instructor'" toplabel="Instructor"/>
                <linkbutton class="recordlabel bold" @click="sortBy='course'" toplabel="Course"/>
                <!--<p>Date/Time</p>-->
                <!--<p>Location</p>-->
                <!--<p>Instructor</p>-->
                <!--<p>Course</p>-->
            </div>
            <div v-for="g in sortedgradeList" :key="g.id" :value="g.id" :class="graderecordclass(g)">
                
                <p class="recordlabel">{{g.StudentName}}</p>
                <p class="recordlabel">{{formatDateTime(g.EndDateTime) }}</p>
                <!-- <p class="recordlabel">{{g.LocationName}}</p> -->
                <p class="recordlabel" v-if="showInstructorList">{{g.InstructorName}}</p>
                <p class="recordlabel">{{g.Name}}</p>
                
                
                <div class="horizFlex">
                    <smallbutton :toplabel="GradeButtonLabel(g)" :buttonclass="gradebuttonclass(g)" @click="grade(g)"/>
                    <!-- <smallbutton :toplabel="ProficientButtonLabel(g)" :buttonclass="proficientbuttonclass(g)" @click="toggleProficient(g)"/> -->
                    <div class="removeme">
                        <confirm :redText="paramTrue" :showLink="paramTrue"  body="Do you want to unsubmit this grade sheet to allow editing? All components of the evaluation will remain under the original instructor. Your comment will be added to the original instructor’s." title="Edit" v-show="showEditButton(g)"
                            @yes="unsubmitamdview(g)" />
                    </div>
                        <confirm :buttonclass="proficientbuttonclass(g)" :showButton="paramTrue" body="Do you want to update the proficiency?" :title="ProficientButtonLabel(g)" v-show="showProficiencyButton(g)"
                            @yes="toggleProficient(g)" />
                    
                        
                    <div class="removeme">
                        <confirm :showLink="paramTrue" :body="getRemoveString(g.StudentName)" title="Remove" v-if="!g.isRemoved && !g.IsLocked && !isBatchGrade(g)"
                            @yes="RemoveGrade(g.ID)" />
                        <confirm :showLink="paramTrue" :body="getRemoveString(g.StudentName)" title="Remove" v-if="!g.isRemoved && !g.IsLocked && isBatchGrade(g)"
                            @yes="RemoveBatchGrade(g.evalBatchNumber)" />
                        <label v-if="g.isRemoved" class="removedlabel ">Removed</label>
                    </div>
                </div>
                
            </div>
        </div>

        <timereview ref="timereview" @close="filtergrades"/>
        <modalmessage ref="modalmessage" title="OFFLINE" body="This action is unavailable Offline."/>
        <modalmessage ref="unsubmitted" title="UNSUBMITTED EVALUATIONS" :body="unsubmittedtext"/>
    </div>
</template>
  
  <script>
  import {mapFields} from 'vuex-map-fields'
  import datamethods from '../../services/datamethods.js'
  import format from '../../format.js'
  import smallbutton from '../controls/button.vue'
  import linkbutton from '../controls/linkbutton'
  import clockin from './clockin.vue'
  import clockout from './clockout.vue'
  import confirm from '../actions/confirm.vue'
  import timereview from './timereview.vue'
  import modalmessage from '../controls/modalmessage.vue'
  import securityhelper from '../../services/securityhelper.js'
  import evalhelper from '../../services/evalhelper.js'
  export default {
      name:'home',
      mixins:[datamethods, format,securityhelper, evalhelper],
      components:{
          smallbutton,
          linkbutton,
          clockin,
          clockout,
          confirm,
          timereview,
          modalmessage

     
   
      },
      data(){
          return{
            instructorIn:false,
            studentsIn:false,
            

            paramTrue:true,
            paramFalse:false,   
            listenersConnected:true,
            
            showLookup:false,
            cachedInstances:null,
            currentDate:null,
            sortBy:'default'
          }
      },
      computed:{
        sortedgradeList(){
            if(this.sortBy=='default'){return this.gradeList}

            //console.log('sort ' + this.sortBy)

            switch (this.sortBy) {
                case 'name':
                    function compare(a,b){
                        if (a.StudentName < b.StudentName)
                            return -1
                        if(a.StudentName > b.StudentName)
                            return 1
                        return 0
                    }
                    return this.gradeList.sort(compare)
                    break;
                case 'date':
                    function compared(a,b){
                        if (a.EndDateTime < b.EndDateTime)
                            return -1
                        if(a.EndDateTime > b.EndDateTime)
                            return 1
                        return 0
                    }
                    return this.gradeList.sort(compared)
                    break;
                case 'instructor':
                    function comparei(a,b){
                        if (a.InstructorName < b.InstructorName)
                            return -1
                        if(a.InstructorName > b.InstructorName)
                            return 1
                        return 0
                    }
                    return this.gradeList.sort(comparei)
                    break;
                case 'course':
                    function comparec(a,b){
                        if (a.Name < b.Name)
                            return -1
                        if(a.Name > b.Name)
                            return 1
                        return 0
                    }
                    return this.gradeList.sort(comparec)
                    break;

                default:
                    return this.gradeList
                    break;
            } 
            

            
        },
        unsubmittedtext(){
            return "You have " + this.oldUnsubmittedCount + " Unsubmitted Evaluation(s)"
        },
        showStudentGroup(){
            if(window.SHOW_STUDENTGROUP==undefined){return false}
            else{return window.SHOW_STUDENTGROUP}
        },
        showProductFilter(){
               
            if(window.SHOW_PRODUCT_FILTER==undefined){return true}
            else{return window.SHOW_PRODUCT_FILTER}
        },
        includeAllUnsubmitted(){
            
            if(window.INCLUDE_ALL_UNSUBMITTED==undefined){return true}
            else{return window.INCLUDE_ALL_UNSUBMITTED}
        },
        lookuptogglelabel(){
            if(this.showLookup){return "Hide Student Lookup"}
            else{return "Show Student Lookup"}
        },
        studentbuttonclass(){
            if(this.studentsIn){return "buttonred widebutton"}
            else{return "buttonprimary widebutton"}
        },
        instructorbuttonclass(){
            if(this.instructorIn){return "buttonred widebutton"}
            else{return "buttonprimary widebutton"}
        },
        instructorClockInLabel(){
            if(this.instructorIn){return "Clock Me OUT"}
            else{return "Clock Me In"}
        },
        ShowClockInInstructor(){
            if(window.SHOW_CLOCKIN_INSTRUCTOR==undefined){return false}
            else{return window.SHOW_CLOCKIN_INSTRUCTOR}
        },
          //image
          imagePathPerson(){
  
              var path = this.employeecurrentPerson.personImageFileName;
              if(path==''){return}
              return path
              /* console.log(this.imageBasePath + path)
              return this.imageBasePath + path; */
          },
          
          ...mapFields([
              
              'loggedInUser.isAdmin',
              'pleaseWait',
              'pleaseWaitText',
             'imageBasePath',
             'selectedGradeSheet',
             'selectedInstance',
             'selectedInstanceList',
             'isManualTime',
             'locationList',
             'loggedInUser',
             'filter',
             'courseList',
             'studentList',
             'instructorList',
             'gradeList',
             'timeReviewData',
             'online',
             'unchangedInstance',
             'commentList',
             'timeData',
             'selectedTimeData',
             'lastAddedEvalId',
             'lastAddedBatchNumber',
             'procedureList',
             'productList',
             'studentGroupList'
          ]),
          useCommentList(){
                if(window.USE_COMMENT_LIST==undefined){return false}
                else{return window.USE_COMMENT_LIST}
            },
            showInstructorList(){
                return this.canAccess('View Grades Other Instructor')
            },
            oldUnsubmittedCount(){
                var count = 0
                var currDate = this.currentDate
                if(this.includeAllUnsubmitted){
                    this.gradeList.forEach(myFunction2)
                        function myFunction2(item, index, arr) {
                        let inst=arr[index]
                        if(inst.EndDateTime < currDate && !inst.IsLocked){   
                            count = count + 1
                        } 
                    }
                }
                return count
            },
            allowEdit(){
                if(window.ALLOW_EDIT_AFTER_SUBMIT==undefined){return false}
                else{return window.ALLOW_EDIT_AFTER_SUBMIT}
            },
            
            autoStartNewSheet(){
                if(window.AUTO_START_NEW_SHEET==undefined){return false}
                else{return window.AUTO_START_NEW_SHEET}
            },
            autoOpenNewSheet(){
                if(window.AUTO_OPEN_NEW_SHEET==undefined){return false}
                else{return window.AUTO_OPEN_NEW_SHEET}
            },
      },
      props:{
          
          
          
      },
      methods:{
        //revised instance
        
        async getSequencesForCache(){
           // console.log('get my sequences')
            var scope = this;
            var cacheseq = localStorage.sequences
            var sequences=[]
            if(cacheseq!=undefined){
                sequences=JSON.parse(cacheseq)
            }
          
            this.gradeList.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    //see if we already have it
                    if(!scope.haveSequence(sequences,inst.ProcedureSequenceId)){
                        //NOPE! go get it
                       // console.log('get seq ' + inst.ProcedureSequenceId )
                        let req = {
                            id:inst.ProcedureSequenceId,
                        }
                        var response = await scope.callAPI(req,'proceduresequence')
                        //handle response
                        if(response.Successful){
                      
                            sequences.push(response.Procedure)
                           
                            localStorage.sequences = JSON.stringify(sequences)
                        }
                    }else{
                        //console.log('HAVE seq ' + inst.ProcedureSequenceId)
                    }
                       
                   
                }
            localStorage.sequences=JSON.stringify(sequences)
        },
        
        

        gotoreports(){
            this.$emit('gotoreports')
        },
        resetfilter(){
          //  console.log('reset')
            this.filter.startDate=this.$options.filters.formatDateForInputToday()
            this.filter.endDate=this.$options.filters.formatDateForInputToday()
            this.filter.studentId=-1
            this.filter.instructorId=this.loggedInUser.user_personId
            this.filter.locationId=this.loggedInUser.LocationId
            this.filter.procedureSequenceId = -1
            this.filter.includeComplete=false
            this.filter.includeInactive=false
            this.filter.studentGroupId=-1
        },
        togglelookup(){
          //  console.log('toggle')
            this.showLookup=!this.showLookup
            localStorage.showLookup = this.showLookup
        },
        gradeheaderclass(){
            var myclass 
            if(this.showInstructorList){
                myclass="graderecordwithinst"
            }else{myclass="graderecord"}
            return myclass
        },
        graderecordclass(g){
            var myclass 
            if(this.showInstructorList){
                myclass="graderecordwithinst"
            }else{myclass="graderecord"}

            if(g.isRemoved){
                myclass=myclass + " graderecordremoved"
            }
            else if(this.isBatchGrade(g)){
                myclass=myclass + " graderecordbatch"
            }
            else if(g.StudentIsProficient_ThisItem){
                myclass=myclass +  " graderecordproficient"
            }else{myclass=myclass +  " graderecordnotproficient"}

            if(this.includeAllUnsubmitted){
                if(g.EndDateTime < this.currentDate && !g.IsLocked){
                    myclass=myclass + ' redborder'
                }
            }
            return myclass
        },
        afterClockOut(){
            if(this.timeReviewData!=null){
                if(this.timeReviewData.length>0){
                    if(this.timeReviewData[0].EmployeeTimeItems.length>0)
                    this.$refs.timereview.Start()
                }
            }
            
            this.getStudentClockStatus()
        },
        showtime(){
            this.$refs.timereview.Start()
        },
        refreshafterClockIn(lid){
       //     console.log('loc ' + lid)
            if(lid!=this.filter.locationId){this.filter.locationId=lid}
            this.filtergrades(false,false,true)
            this.getStudentClockStatus()
        },
        GradeButtonLabel(grade){
            if(grade.IsLocked){return "View Completed"}
            if(this.isBatchGrade(grade)){return "Enter Grades"}
            else{return "Enter Grades"}
        },
        isBatchGrade(grade){
            //return grade.StudentName.includes('GROUP ')
            return grade.evalBatchNumber>0
        },
        gradebuttonclass(grade){
            if(grade.isFailure){
                return "buttonred recordactionbutton"
            }
            else if(!grade.IsLocked){
                return "buttongreen recordactionbutton"
            }else{return "buttonprimary recordactionbutton"}
        },
        ProficientButtonLabel(grade){
            if(grade.StudentIsProficient_ThisItem){return "IS Proficient"}
            else{return "NOT Proficient"}
        },
        proficientbuttonclass(grade){
            if(!grade.StudentIsProficient_ThisItem){
                return "buttonred recordactionbutton"
            }else{return "buttongreen recordactionbutton"}
        },
        showProficiencyButton(grade){
            return !grade.isRemoved  && !this.isBatchGrade(grade) && grade.showProficiency && this.canAccess('Set Proficiency')
        },
        showEditButton(grade){
            
            if(this.allowEdit==false){return false}
            else if(this.canAccess("Edit Submitted Evals")==false){return false}
            else {
                var showprev = this.GetSequence_Batch_ShowPreviousInstances(grade.ProcedureSequenceId)
                //console.log('showprv '  + showprev)
                return grade.IsLocked && (!this.isBatchGrade(grade) || !showprev)
            }
        },

        getRemoveString(name){
            return "Do you want to remove the grade sheet for " + name + "?"
        },
        async RemoveGrade(id){
            if(!this.online){this.handleOffline('removegrade'); return}

            
            let req = {
                id:id
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'removegrade')
            //handle response
            if(response.Successful){
                this.pleaseWait=false;
                this.filtergrades();
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async RemoveBatchGrade(id){
            if(!this.online){this.handleOffline('removegrade'); return}

            
            let req = {
                id:id
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'removegrade_batch')
            //handle response
            if(response.Successful){
                this.pleaseWait=false;
                this.filtergrades();
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async instructorInOut(){
            if(!this.online){this.handleOffline('instr in/out'); return}
            if(this.instructorIn){
                let req = {
                    personID:this.loggedInUser.user_personId,
                    clubID:this.loggedInUser.LocationId
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'clockout')
                //handle response
                if(response.Successful){
                    this.pleaseWait=false;
                    this.getInstructorClockStatus()
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
            }else{
                let req = {
                    personID:this.loggedInUser.user_personId,
                    clubID:this.loggedInUser.LocationId
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'clockin')
                //handle response
                if(response.Successful){
                    this.pleaseWait=false;
                    this.getInstructorClockStatus()
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
            }
            
        },
        formatDateTime(dt){
            return this.$options.filters.formatDateTime(dt)
        },
        async filtergrades(showunsub,firsttime,afterclockin){
            if(!this.online){ return}
           // console.log('getting fresh grades')
            let req = {
                ProcedureFilter:{
                    StudentID:this.filter.studentId,
                    InstructorID:this.filter.instructorId,
                    LocationID:this.filter.locationId,
                    StartDateTime:this.filter.startDate,
                    EndDateTime:this.filter.endDate,
                    IncludeOnlyActiveStatus:!this.filter.includeInactive,
                    IncludeOnlyCompleteStatus:false,
                    IncludeOnlyIncompleteStatus:!this.filter.includeComplete,
                    ProcedureInstanceStatusID:-1,
                    includeAllUnsubmitted:this.includeAllUnsubmitted,
                    procedureSequenceId:this.filter.procedureSequenceId,
                    productId:this.filter.productId,
                    studentGroupId:this.filter.studentGroupId
                    
                }
                
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'filtergrades')
            //handle response
            if(response.Successful){
                this.sortBy='default'
                 this.gradeList=response.GradedProcedures
                 this.pleaseWait=false;
                 
                 //do offline prep
                 this.processResultsForOffline()

                 var popped =false
                 if(showunsub && this.includeAllUnsubmitted){
                  //  console.log('showunsub')
                    if(this.oldUnsubmittedCount>0){
                        popped=true
                        this.$refs.unsubmitted.show()
                    }
                    
                 }
                 this.pleaseWait=false;
                 //if we are NOT showing unsubmitted warning, it IS first filter and  we DO auto start
                 if(!popped){
                    if(firsttime){
                        if(this.autoStartNewSheet){
                            this.$refs.clockinbutton.Start()
                        }
                    }
                 }
                 if(afterclockin){
                    if(this.autoOpenNewSheet){
                        if(this.lastAddedEvalId>0){
                          //  console.log('auto open ' + this.lastAddedEvalId)
                            this.pleaseWait=true;
                            var newInst = this.getInstanceById(this.lastAddedEvalId)
                            if(newInst!=null){
                              //  console.log('found it ' + this.lastAddedEvalId)
                                this.grade(newInst)
                            }
                            
                        }
                        if(this.lastAddedBatchNumber>0){
                           // console.log('auto open batch ' + this.lastAddedBatchNumber)
                            this.pleaseWait=true;
                            var newInst = this.getBatchInstanceById(this.lastAddedBatchNumber)
                            if(newInst!=null){
                              //  console.log('found it batch ' + this.lastAddedBatchNumber)
                                this.grade(newInst)
                            }
                            
                        }
                    }
                 }
            }else{
                this.responseMessage = response.Message
            }
            
        },
        getInstanceById(id){
            var thisinst=null
            this.gradeList.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(id== inst.ID){
                        thisinst=inst
                    }
            }
            return thisinst
        },
        getBatchInstanceById(id){
            var thisinst=null
            this.gradeList.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(id== inst.evalBatchNumber){
                        thisinst=inst
                    }
            }
            return thisinst
        },
        studentStatus(){
            this.status_batch()
        },

         async grade(gradesheet){
            //if batch, handle special
            if(this.isBatchGrade(gradesheet)){
                
                this.grade_batch(gradesheet)
                return
            }
            
            //if offline, get stored instance
            if(!this.online){
                this.getCachedInstances(gradesheet.ID)
                return;
            }

            //if online, CHECK stored instance to see if dirty... if yes, use that!
            if(this.getCachedInstancesONLYIfDirty(gradesheet.ID)){
                return;
            }

            //console.log('get fresh instance')
            let req = {
                id:gradesheet.ID,
                autoStartStopTime:!this.isManualTime
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'procedureinstance')
            //handle response
            if(response.Successful){
                 this.selectedInstance=response.ProcedureInstance
                 //***this.selectedGradeSheet = response.ProcedureSequence
                 //***this.selectedInstance.Procedure = this.selectedGradeSheet
                 //***this.selectedInstanceList = response.ProcedureInstanceList
                this.selectedInstanceList=response.ProcedureInstance.instanceHistory
                
                
                //if more than 6 chop them off
                if(this.selectedInstanceList!=null){
                    //remove until we have 5
                   // console.log('len before ' + this.selectedInstanceList.length)
                    while(this.selectedInstanceList.length>6){
                        this.selectedInstanceList.shift()
                    }
                  //  console.log('len after ' + this.selectedInstanceList.length + ' ')
                }
                

                //***get sequence! */
                this.selectedGradeSheet=this.GetSequence(this.selectedInstance.ProcedureSequenceId)

                //replace our stored one with this one!
                this.replaceCachedInstance(this.selectedInstance)
                this.pleaseWait=false; 
                this.$emit('gotograde')
               
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 

          },
          async unsubmitamdview(gradesheet){
            let req = {
                ProcedureInstance:gradesheet,
                loggedInUser:this.loggedInUser.user_personId,
                loggedInUserName:this.loggedInUser.name
                
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'unsubmitprocedureinstance')
            //handle response
            if(response.Successful){
                //this.pleaseWait=false;  
                this.grade(gradesheet)
                
                
            }else{
                this.responseMessage = response.Message
                this.pleaseWait=false; 
            }
            //this.pleaseWait=false; 
          },
          async grade_batch(gradesheet){
            if(!this.online){
                this.getCachedInstances_batch(gradesheet.evalBatchNumber)
                return;
            }
          //  console.log('get batch')
            let req = {
                evalBatchNumber:gradesheet.evalBatchNumber,
                procedureSequenceId:gradesheet.ProcedureSequenceId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'procedureinstance_batch')
            //handle response
            if(response.Successful){
                 //***this.selectedGradeSheet = response.ProcedureSequence
                 this.selectedInstanceList = response.ProcedureInstanceList
                this.selectedInstance = this.selectedInstanceList[0]
                this.pleaseWait=false;

                //***get sequence! */
                this.selectedGradeSheet=this.GetSequence(this.selectedInstance.ProcedureSequenceId)

                this.$emit('gotograde_batch')
               
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async status_batch(){
            this.$emit('gotostatus')
            /* console.log('get status')
            let req = {
                locationId:11004001,
                procedureSequenceId:30001244
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'procedureinstance_batch_seq')
            //handle response
            if(response.Successful){
                 this.selectedGradeSheet = response.ProcedureSequence
                 this.selectedInstanceList = response.ProcedureInstanceList
                this.selectedInstance = this.selectedInstanceList[0]
                
                this.$emit('gotostatus')
               
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;  */
          },
          async getCommentList(){
            if(!this.online){return}
            
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(null,'evalcomments')
            //handle response
            if(response.Successful){
                 this.commentList=response.EvalCommentList
                if(window.ALLOW_CUSTOM_COMMENT!=undefined){
                    if(window.ALLOW_CUSTOM_COMMENT){
                        var custom = {id:-2,name:'Custom'}
                        this.commentList.push(custom)
                    }
                }
                 
                 this.pleaseWait=false;
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async getProducts(){
                let req = {
                    LocationID:this.filter.locationId
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'products')
                //handle response
                if(response.Successful){
                    this.productList=response.productList
                    var all = {id:-1, name:'--All Courses--'}
                 this.productList.unshift(all)
                    this.filterByProductId=-1
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
          },
          async getLocations(firsttime){
            if(!this.online){this.handleOffline('getloc');return}
            let req = {
                id:this.loggedInUser.user_personId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'locations')
            //handle response
            if(response.Successful){
                 this.locationList=response.GradeLocations
                 this.pleaseWait=false; 
                 //var all = {id:-1,name: '--All Locations--'}
                 //this.locationListWithAll.unshift(all)
                 if(this.loggedInUser.LocationId>0 ){
                    this.filter.locationId=this.loggedInUser.LocationId
                    this.selectLocation(firsttime);
                 }else{
                    this.filter.locationId=-1
                    this.selectLocation(firsttime);
                 }
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getStudents(){
            if(!this.online){this.handleOffline('getstu');return}
            let req = {
                LocationID:this.filter.locationId,
                includeInactive:false
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'students')
            //handle response
            if(response.Successful){
                 this.studentList=response.PersonList
                 var all = {id:-1, name:'--All Students--'}
                 this.studentList.unshift(all)
                this.filter.StudentIDstudentId=-1
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getInstructors(){
            if(!this.online){this.handleOffline('getstu');return}
            let req = {
                LocationID:this.filter.locationId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'instructors')
            //handle response
            if(response.Successful){
                 this.instructorList=response.PersonList
                 var all = {id:-1, name:'--All instructors--'}
                 this.instructorList.unshift(all)
                this.filter.instructorId=this.loggedInUser.user_personId
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getCourses(){
            if(!this.online){this.handleOffline('getcourse');return}
            let req = {
                LocationID:this.filter.locationId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'courses')
            //handle response
            if(response.Successful){
                 this.courseList=response.TimeEventList
                 var all = {id:-1, name:'--All Courses--'}
                 this.courseList.unshift(all)
                this.courseId=-1
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getProcedures(){
            if(!this.online){this.handleOffline('getprocedures');return}
            let req = {
                id:-1
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'procedures')
            //handle response
            if(response.Successful){
                 this.procedureList=response.ProcedureList
                 var all = {id:-1, name:'--All Training--'}
                 this.procedureList.unshift(all)
                this.filter.procedureSequenceId=-1
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          selectLocation(firsttime){
           // console.log('loc')
            this.getStudents();
            this.getInstructors();
            this.getProcedures();
           this.getProducts();
            
            this.filtergrades(true,firsttime);
           
            
          },
          async getInstructorClockStatus(){
            if(!this.online){return}
            let req = {
                id:this.loggedInUser.user_personId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'clockstatus')
            //handle response
            if(response.Successful){
                 this.instructorIn=response.isIn
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getStudentClockStatus(){
            if(!this.online){ return}
            let req = {
                    locationId:this.locationId,
                    RestrictToNotClockedInNow:false,
                    RestrictToClockedInNow:true
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'studentsforinout')
                //handle response
                if(response.Successful){
                    this.studentsIn=response.PersonList.length>0
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
          },
          async toggleProficient(grade){
            if(!this.online){this.handleOffline('prof'); return}
            let req = {
                ProcedureInstance:grade,
                loggedInUser:this.loggedInUser.user_personId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'toggleproficiency')
            //handle response
            if(response.Successful){
                grade.StudentIsProficient_ThisItem=!grade.StudentIsProficient_ThisItem    
               this.pleaseWait=false;
               this.filtergrades()    
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
            
        },

        //offline
        handleOffline(text){
                this.$refs.modalmessage.show()
              //  console.log('offline... no soup for you '  + text)
            },
        processResultsForOffline(){
            //store copy in local storage
            //localStorage.lastGradeList = JSON.stringify(this.gradeList)
            //localStorage.lastFilter = JSON.stringify(this.filter)

            //go get the instances for gradables for offline work
            this.getInstancesForOffline()
            this.getSequencesForCache()
        },
        async getInstancesForOffline(){
            var scope = this;
            var instances=[]//JSON.parse(localStorage.instances)
            this.gradeList.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(!inst.IsLocked && inst.ID>0){
                        let req = {
                            id:inst.ID,
                            includeHistory:false,
                            autoStartStopTime:false
                        }
                        var response = await scope.callAPI(req,'procedureinstance')
                        //handle response
                        if(response.Successful){
                           //*** */ response.ProcedureInstance.Procedure = response.ProcedureSequence
                            instances.push(response.ProcedureInstance)
                            localStorage.instances = JSON.stringify(instances)
                            //console.log('stored the instances ' + instances.length)
                        }
                    }else if(!inst.IsLocked && inst.evalBatchNumber>0){
                        //BATCHES!
                        let req = {
                            evalBatchNumber:inst.evalBatchNumber,
                            procedureSequenceId:inst.ProcedureSequenceId
                        }
                        
                        var response = await scope.callAPI(req,'procedureinstance_batch')
                        //handle response
                        if(response.Successful){
                            response.ProcedureInstanceList.forEach(myFunction3)
                            async function myFunction3(item, index, arr) {
                                let inst=arr[index]
                                if(!inst.IsLocked && inst.ID>0){
                                     instances.push(inst)
                                }
                            }
                            localStorage.instances = JSON.stringify(instances)
                       
                        }
                    }
                }
            localStorage.instances=JSON.stringify(instances)
            //console.log('stored the instances ' + instances.length)
        },
        getCachedInstances(id){
            var storedinstances = localStorage.instances
            if(storedinstances==undefined){return}

            var myinst
            var insts = JSON.parse(storedinstances);
            this.cachedInstances= insts
              //  console.log('got instances from cache ' + id)

            insts.forEach(myFunction2)
                 function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(id==inst.ID){
                        myinst=inst
                    
                       
                    }
                 }
                
            this.selectedInstance=myinst
            //***get sequence! */
            this.selectedGradeSheet=this.GetSequence(this.selectedInstance.ProcedureSequenceId)
            this.selectedInstanceList = [myinst]
             this.unchangedInstance = JSON.parse(JSON.stringify(myinst)) 
            //get a copy to rever to on a cancel
            this.$emit('gotograde')
        },
        getCachedInstances_batch(id){
           // this.handleOffline()
           // return;
            
            var storedinstances = localStorage.instances
         //   console.log('stored ' + id)
            if(storedinstances==undefined){return}

            var myinst
            var myinstlist=[]
            try {
                var insts = JSON.parse(storedinstances);
                this.cachedInstances= insts
                //  console.log('got instances from cache ' + id)

                 insts.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                        let inst=arr[index]
                        if(id==inst.evalBatchNumber){
                            myinstlist.push(inst)
                        
                        
                        }
                    }
                    
                
                
                this.selectedInstanceList = myinstlist
                this.selectedInstance=myinstlist[0]
                //***get sequence! */
            this.selectedGradeSheet=this.GetSequence(this.selectedInstance.ProcedureSequenceId) 
                //this.unchangedInstance = JSON.parse(JSON.stringify(myinst)) 
                //get a copy to rever to on a cancel
                this.$emit('gotograde_batch')  
            } catch (error) {
              //  console.log('failed to get batch isnts' + error)
            }
            
        },
        replaceCachedInstance(newinst){
            //replace the one local with this one if ID's match

            var locinst = localStorage.instances;
            if(locinst==undefined){return}
            var insts = JSON.parse(locinst);
           // this.cachedInstances= insts
             //   console.log('replace cached instance ' + newinst.ID)

            insts.forEach(myFunction2)
                 function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(newinst.ID==inst.ID){
                        arr[index]=newinst
                     //   console.log('found and replaced inst')
                    }
                 }
            localStorage.instances = JSON.stringify(insts)
        },

        getCachedInstancesONLYIfDirty(id){
          //  console.log('check cache for diry inst ' + id)
            var isDirty=false
            var myinst

            var locinst = localStorage.instances;
            if(locinst==undefined){return}
            var insts = JSON.parse(locinst);
            this.cachedInstances= insts
            
          //  console.log(insts)
            insts.forEach(myFunction2)
                 function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    //console.log('check inst ' + inst.ID + ' ' + inst.dirty)
                    if(id==inst.ID && inst.dirty){
                        myinst=inst
                        isDirty=true
                       // console.log('got instances from cache because cache DIRTY! ' + id)
                    }
                 }
            if(isDirty)   {
                this.selectedInstance=myinst
               //** */ this.selectedGradeSheet = myinst.Procedure   
                this.selectedInstanceList = [myinst]
                this.unchangedInstance = JSON.parse(JSON.stringify(myinst)) //get a copy to rever to on a cancel
                this.$emit('gotograde')
                return true;
            }
            return false;
        },
        saveDirtyInstances(){
           // console.log('savedirtyinstances')
            var scope = this
            var locinst = localStorage.instances;
            if(locinst==undefined){return}
            var insts = JSON.parse(locinst);
            insts.forEach(myFunction2)
                 function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(inst.dirty){
                        scope.saveInstance(inst)
                        arr[index].dirty=false
                    }
                 }
        },
        async saveInstance(inst){
            let req = {
                ProcedureInstance:inst,
                submit:false,
                autoStartStopTime:!this.isManualTime
            }
             this.$store.dispatch('beforeAPI');
             //console.log('saving from home')
            var response = await this.callAPI(req,'saveprocedureinstance')
            //handle response
            if(response.Successful){
                             
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async getGroupList(){
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(null,'studentgroups')
            //handle response
            if(response.Successful){
                this.studentGroupList=response.ValueList
                var all = {ID:-1, Name:'--All Student Groups--'}
                this.studentGroupList.unshift(all)
                this.filterBySponsor=-1
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
            
           
        },

      },
      mounted(){
        
       /*  //try to get from storage
        var tmp = localStorage.lastFilter
        if(tmp!=undefined){
            this.filter =  JSON.parse(tmp)  
        } */
        //try to get grades from storage
        /* var tmp3 = localStorage.lastGradeList
            if(tmp3!=undefined){
                this.gradeList =  JSON.parse(tmp3)
                console.log('getting cached grades')
            } */

        

        this.currentDate = this.$options.filters.formatDateForInputToday()

        var tmp2 = localStorage.showLookup
        //console.log('show ' + tmp2)
        if(tmp2!=undefined){
            this.showLookup=tmp2=="true"
        }

        if(this.filter.locationId==-1){
        //    console.log('first open!')
            localStorage.removeItem('sequences')
            this.saveDirtyInstances()
            this.resetfilter()
            //this.filtergrades()
        }
        
        if(this.locationList.length==0){
            this.getLocations(true);
        }
        /* if(this.filter.instructorId!=this.loggedInUser.user_personId){
            this.getLocations(true);
            this.resetfilter()
            this.filtergrades()
        } */

        this.getInstructorClockStatus();
        this.getStudentClockStatus();
        if(this.useCommentList){this.getCommentList()}
        if(window.SHOW_STUDENTGROUP){this.getGroupList()}
      },
      
  }
  </script>
  
  <style>
  .removedlabel{
    color:red;
    margin-left:10px;

  }
  .homeDiv{
    display:grid;
    grid-template-rows: auto auto auto;
  }
  .buttons{
    display:flex;
    width:100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .filter{
    padding-left:10px;
  }
  .graderecord{
    text-align:left;
    
    margin:5px;
    border-radius: 2px;
    padding:0px 0px 0px 5px;
    display:grid;
    grid-template-columns: 2fr  1fr 1fr 320px;
  }
  .graderecordwithinst{
    text-align:left;
    
    margin:5px;
    border-radius: 2px;
    padding:0px 0px 0px 5px;
    display:grid;
    grid-template-columns: 2fr  1fr 1fr 1fr 320px;
  }
  .graderecordproficient{
    background-color:rgb(195, 219, 195);
  }
  .graderecordremoved{
    background-color:lightgray;
  }
  .graderecordnotproficient{
    background-color:var(--frame-color);
  }
.graderecordbatch{
    background-color:lightblue;
}
.recordactionbutton{

border: none;
border-radius:5px;
width:110px;
height:40px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;

}
.filterfield{
    height:30px;
    margin:5px;
    max-width:110px;
    font-size:inherit;
}
.filterselect{
    height:35px;
    margin:5px; 
    min-width: 125px;
    font-size:inherit;
}
.filterlabel{
    font-size:larger;
    margin:10px 0px 0px 0px;
    color:var(--text-color);
}
.removeme{
    margin-top:10px;
    margin-left:5px;
}
.redborder{
    border:2px red solid;
}
  </style>


/*  this.selectedGradeSheet=gradesheet
            
            this.selectedGradeSheet.previousComments='this is a really long comment. I have a lot to say about this guy. I think its important that you read the whole thing very carefully.'
            this.selectedGradeSheet.instances=[
                {
                    id:1,
                    status:'completed',
                    dateTime:'8/7',
                    grades:[
                        {
                            componentId:1,
                            grade:3
                        },
                        {
                            componentId:2,
                            grade:3
                        },
                        {
                            componentId:3,
                            grade:3
                        }
                        ,
                        {
                            componentId:4,
                            grade:null
                        }
                    ]
                },
                {
                    id:2,
                    dateTime:'8/8',
                    status:'completed',
                    grades:[
                        {
                            componentId:1,
                            grade:5
                        },
                        {
                            componentId:2,
                            grade:5
                        },
                        {
                            componentId:3,
                            grade:5
                        }
                        ,
                        {
                            componentId:4,
                            grade:5
                        }
                    ]
                },
                {
                    id:3,
                    dateTime:'8/9',
                    status:'inprocess',
                    grades:[
                        {
                            componentId:1,
                            grade:null
                        },
                        {
                            componentId:2,
                            grade:null
                        },
                        {
                            componentId:3,
                            grade:null
                        }
                        ,
                        {
                            componentId:4,
                            grade:null
                        }
                        ,
                        {
                            componentId:5,
                            grade:null
                        }
                    ]
                }
            ]
            this.selectedGradeSheet.steps=[
                {
                    id:1,
                    name:'Vehicle Inspection',
                    components:[
                        {
                            id:1,
                            name:'Pre-Trip',
                         
                        },
                        {
                            id:2,
                            name:'LAB',
                            
                        },
                        {
                            id:3,
                            name:'RR Xing',
                            
                        },
                    ]
                },
                {
                    id:1,
                    name:'Safety',
                    components:[
                        {
                            id:4,
                            name:'Following Direction',
                            
                        },
                        {
                            id:5,
                            name:'Check for Clear Intersections',
                          
                        },
                        {
                            id:6,
                            name:'General Safety',
                          
                        },
                        
                    ]
                },
                {
                    id:1,
                    name:'Driving Techniques',
                    components:[
                        {
                            id:7,
                            name:'Up-shifting',
                            
                        },
                        {
                            id:8,
                            name:'Down-shifting',
                          
                        },
                        {
                            id:9,
                            name:'Right Turns',
                          
                        },
                        {
                            id:10,
                            name:'Left Turns',
                          
                        },
                        {
                            id:11,
                            name:'Braking',
                          
                        },
                        {
                            id:12,
                            name:'Turn Signals',
                          
                        },
                        {
                            id:13,
                            name:'Speed Management',
                          
                        },
                        {
                            id:14,
                            name:'Space Management',
                          
                        },
                        {
                            id:15,
                            name:'Intersections',
                          
                        },
                        {
                            id:16,
                            name:'Traffic Checks',
                          
                        },
                        {
                            id:17,
                            name:'Passing',
                          
                        },
                        {
                            id:18,
                            name:'Centering',
                          
                        },
                    ]
                },
                {
                    id:1,
                    name:'Driver Awareness',
                    components:[
                        {
                            id:3,
                            name:'left turn',
                            
                        },
                        {
                            id:4,
                            name:'right turn',
                          
                        },
                        {
                            id:5,
                            name:'more stuff',
                          
                        },
                        {
                            id:6,
                            name:'another thing',
                          
                        },
                        {
                            id:7,
                            name:'fancy manuver',
                          
                        },
                        {
                            id:8,
                            name:'stop',
                          
                        },
                        {
                            id:9,
                            name:'start',
                          
                        },
                    ]
                },
            ]
            this.selectedInstance=this.selectedGradeSheet.instances[0]    */