import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import {store} from './store/store'
import {VOffline} from 'v-offline'
import VueHtmlToPaper from 'vue-html-to-paper';


const options = {
    name: '_blank',
    specs: [
      'fullscreen=yes',
      'titlebar=yes',
      'scrollbars=yes'
    ],
    styles: [
      'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
      'https://unpkg.com/kidlat-css/css/kidlat.css'
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
  }


//createApp(App).mount('#app')

const app = createApp(App)
app.use(store)

app.component('VOffline',VOffline)
app.use(VueHtmlToPaper);
app.mount('#app')