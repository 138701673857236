<template>
    <div>
      
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <label class="titlelabel">Which would you like to view? </label>
                <div class="centered" >
                    <div class="">
                        <div v-for="s in selectedGradeSheet.ProcedureStepList" :key="s.ID" :value="s.ID" class="compgrid" >
                            <div v-for="c in s.StepComponentList" :key="c.ID" :value="c.ID" class="horizFlex">
                                <div class="horizFlex " v-if="c.IsActive">
                                    <input type="checkbox" v-model="c.selected"/>
                                    <p class="leftjustified ">{{ abbrevComp(c.Name) }}</p>
                                </div>
                                
                            </div>  
                        </div>
                    </div>
                    
                    <div class="leftjustified">
                        <button  @click="all()" class="right10" >Select All</button>
                        <button  @click="clear()"  >Clear All</button>
                        
                    </div>  
                    <div class="">
                        <smallbutton  @click="save()" toplabel="Start Grading" />
                        <smallbutton  @click="cancel()" toplabel="Cancel" />
                    </div>              
                </div>
                

             
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import optionbutton from '../controls/optionbutton.vue'
    export default {
        name:'components',
        components:{
            smallbutton,
            actionmodal,
            optionbutton
        },
        props:{
            
            cols:{
                type:Number,
                default:4
            },
    
        },
        mixins:[format],
        data(){
            return{
               
            }
        },
        computed:{
            ...mapFields([
               'selectedGradeSheet'
            ]),
           
        },
        methods:{
            abbrevComp(name){
                var out= name
                out= out.replace('Chapter','Ch').replace('Score','')
                out =out.replace('Chap','Ch')
                var dash = out.indexOf('-')
                if(dash>0){out = out.substring(0,dash-1)}
                return out
            },
           Update(){
               
                this.$refs.mymodal.openModal()
           },
           
           all(){
            this.selectedGradeSheet.ProcedureStepList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    comp.StepComponentList.forEach(myFunction3)
                    function myFunction3(item, index, arr) {
                        arr[index].selected=true
                        
                    }
                }
            /* this.selectedGradeSheet.ProcedureStepList[0].StepComponentList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    arr[index].selected=true
                } */
           },
           clear(){
            this.selectedGradeSheet.ProcedureStepList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    comp.StepComponentList.forEach(myFunction3)
                    function myFunction3(item, index, arr) {
                        arr[index].selected=false
                        
                    }
                }
            /* this.selectedGradeSheet.ProcedureStepList[0].StepComponentList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    arr[index].selected=false
                } */
           },
            save(){
                this.$emit('save')
                this.$refs.mymodal.closeModal()

            },
            cancel(){
                this.$emit('cancel')
                this.$refs.mymodal.closeModal()
            }
        }
    }
    </script>
    
    <style>
    .compgrid{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .centervert{
        vertical-align: middle;
    }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    