<template>
    <div>
        <!-- <linkbutton toplabel="Update person" @click="UpdatePerson()"/> -->
        <!-- <smallbutton @click="Update()" :toplabel="updateText" :class="buttonclass"/> -->
        <!-- ACCT NOTE MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <label class="titlelabel">Preferences</label>
                <div class="centered" >
                    <optionbutton :itemList="colList" :initialSelectedId="colCount" @select="setColCount"/>
                    
                    
                    <div >
                        <smallbutton  @click="save()" toplabel="Save" />
                        <smallbutton  @click="cancelupdate()" toplabel="Cancel"/>
                    </div>                
                </div>
                

             
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import optionbutton from '../controls/optionbutton.vue'
    export default {
        name:'preferences',
        components:{
            smallbutton,
            actionmodal,
            optionbutton
        },
        props:{
            
            cols:{
                type:Number,
                default:4
            },
    
        },
        mixins:[format],
        data(){
            return{
                colCount:1,
                colList:[
                    {
                        id:1,
                        name:'1'
                    },
                    {
                        id:2,
                        name:'2'
                    },
                    {
                        id:3,
                        name:'3'
                    },
                    {
                        id:4,
                        name:'4'
                    }   

                    
                ]
            }
        },
        computed:{
            ...mapFields([
               
            ]),
           
        },
        methods:{
            setColCount(id){
                this.colCount=id
            },
           Update(){
           
            this.colCount = this.cols
            this.$refs.mymodal.openModal()
           },
           
            cancelupdate(){
                this.$refs.mymodal.closeModal()
            
            },
          
            save(){
                this.$emit('save', this.colCount)
                this.$refs.mymodal.closeModal()

            }
        }
    }
    </script>
    
    <style>
    .gradegrid{
        display:grid;
        grid-template-columns: 1fr 5fr;
        text-align:left;
    }
    .selectgradebutton{
    height:70px;
    width:70px;
    background-color: var(--frame-color);
    border:1px solid gray;
    font-size:larger;
    margin:5px;
  }
  .gradelabel{
    margin: 25px 0px 0px 10px;
  }
  .gradeinput{
    text-align: left;
    width:500px;

  }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    