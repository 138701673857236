<template>
    <div class="wheeldiv">    
        <input type="checkbox" v-model="check0" @change="checkme()"/>
        <input type="checkbox" v-model="check1" @change="checkme()"/>
        <p></p>
        <input type="checkbox" v-model="check2" @change="checkme()"/>
        <input type="checkbox" v-model="check3" @change="checkme()"/>

        <input type="checkbox" v-model="check4" @change="checkme()"/>
        <input type="checkbox" v-model="check5" @change="checkme()"/>
        <p></p>
        <input type="checkbox" v-model="check6" @change="checkme()"/>
        <input type="checkbox" v-model="check7" @change="checkme()"/>
    </div>
</template>

<script>
export default {
    name:'flashers',
    props:{
        
        checkOffData:{
            type:String,
            default:'',
        },
        componentId:{
            type:Number
        },
        instance:{
           type:Object 
        }
        
    },
    watch:{
        checkOffData:{
            immediate:true,
            handler(val,oldVal){
                this.setChecks()
            }
        }
    },
    data() {
        return {
            outData:null,
            check0:false,
            check1:false,
            check2:false,
            check3:false,
            check4:false,
            check5:false,
            check6:false,
            check7:false,
     

        };
    },
    methods:{
        checkme(){
            
            var d= this.check0
            d=d +  "|" + this.check1
            d=d  +  "|" + this.check2
            d=d  +  "|" + this.check3
            d=d  +  "|" + this.check4
            d=d  +  "|" + this.check5
            d=d  +  "|" + this.check6
            d=d  +  "|" + this.check7
          
            this.outData = d
            //console.log('checkme' + this.outData)
            this.$emit('checkoff',this.allChecked, this.outData,this.componentId, this.instance)
        },
        setChecks(){
            if(this.checkOffData==null){return}
            var myarray = this.checkOffData.split("|")
            this.check0=myarray[0]=="true" || myarray[0]=="1"
            this.check1=myarray[1]=="true" || myarray[1]=="1"
            this.check2=myarray[2]=="true" || myarray[2]=="1"
            this.check3=myarray[3]=="true" || myarray[3]=="1"
            this.check4=myarray[4]=="true" || myarray[4]=="1"
            this.check5=myarray[5]=="true" || myarray[5]=="1"
            this.check6=myarray[6]=="true" || myarray[6]=="1"
            this.check7=myarray[7]=="true" || myarray[7]=="1"
        
        }
    },
    computed:{
        allChecked(){
            return this.check0 && this.check1 && this.check2 && this.check3 && this.check4 && this.check5 && this.check6 && this.check7 
        }

    }
}
</script>

<style scoped>
.wheeldiv{
    display:grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
    grid-template-rows:1fr 1fr;
}
</style>