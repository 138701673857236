<template>
    <div>
        <!-- <linkbutton toplabel="Update person" @click="UpdatePerson()"/> -->
        <!-- <smallbutton @click="Update()" :toplabel="updateText" :class="buttonclass"/> -->
        <!-- ACCT NOTE MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <label class="titlelabel">Add Student to Group</label>
                <div class="centered" >
                    
                    
                    <select name="" id="" class='entrySelect' required v-model="studentId" >
                        <option v-for="c in studentList" :key="c.id" :value="c.id" >
                            {{c.name}}
                        </option>
                    </select>
                
                    <div >
                        <smallbutton  @click="save()" toplabel="Save" />
                        <smallbutton  @click="cancelupdate()" toplabel="Cancel"/>
                    </div>
                    <p class="responsemessage">{{ responseMessage }}</p>                
                </div>
             
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import datamethods from '../../services/datamethods.js'
    export default {
        name:'addstudent',
        components:{
            smallbutton,
            actionmodal,
   
        },
        props:{
            locationId:{
                type:Number
            },
           
    
        },
        mixins:[format,datamethods],
        data(){
            return{
                studentId:-1,
                studentList:[],
                responseMessage:null
            }
        },
        computed:{
            ...mapFields([
                
                'online',
                'selectedInstanceList'
            ]),

        },
        methods:{
           
           Update(){
            this.getStudents()
            this.studentId=-1
            this.$refs.mymodal.openModal()
           },
           
            cancelupdate(){
                this.$refs.mymodal.closeModal()
            
            },
          
            save(){
                //make sure that student isn't already in the batch
                var found=false
                var studentId=this.studentId
                this.selectedInstanceList.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    var inst=arr[index]
                    if(inst.StudentID==studentId){
                        found=true
                    }
                }
                if(found){
                    this.responseMessage='The selected student is already in the group'
                    return
                }
                this.$emit('save', this.studentId)
                this.$refs.mymodal.closeModal()

            },
            async getStudents(){
                if(!this.online){this.handleOffline('getstu');return}
                let req = {
                    LocationID:this.locationId,
                    includeInactive:false
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'students')
                //handle response
                if(response.Successful){
                    this.studentList=response.PersonList
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
            },
           
        }
    }
    </script>
    
    <style>
    .gradegrid{
        display:grid;
        grid-template-columns: 1fr 5fr;
        text-align:left;
    }
    .selectgradebutton{
    height:70px;
    width:70px;
    background-color: var(--frame-color);
    border:1px solid gray;
    font-size:larger;
    margin:5px;
  }
  .gradelabel{
    margin: 25px 0px 0px 10px;
  }
  .gradeinput{
    text-align: left;
    width:500px;

  }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    