<template>
    <div>
        <!-- <linkbutton toplabel="Update person" @click="UpdatePerson()"/> -->
        <!-- <smallbutton @click="Update()" :toplabel="updateText" :class="buttonclass"/> -->
        <!-- ACCT NOTE MODAL -->
          <actionmodalnarrow ref="mymodal"> 
            <template v-slot:body>
                <div class="centered" v-if="gradeType=='1to5'">
                    <div class="centered">
                        <input ref="numberinput1to5" class="gradeinput2" v-model="enteredGrade"  v-on:keyup.enter="entered()">
                    </div> 
                    <div class="gradegrid">
                        <button class="selectgradebutton" @click="select(1)">1</button>
                        <p class="gradelabel">Needs Significant Training/Practice</p>
                        <button class="selectgradebutton" @click="select(2)">2</button>
                        <p class="gradelabel">Needs More Training/Practice</p>
                        <button class="selectgradebutton" @click="select(3)">3</button>
                        <p class="gradelabel">Is Getting Close to Being Proficient</p>
                        <button class="selectgradebutton" @click="select(4)">4</button>
                        <p class="gradelabel">Has Reached Proficiency Level at this Procedure</p>
                        <button class="selectgradebutton" @click="select(5)">5</button>
                        <p class="gradelabel">Exceeds Proficiency at this Procedure</p>
                        <button class="selectgradebutton" @click="select('NA')" v-if="AllowNAAsGrade">NA</button>
                        <br>
                        <button class="selectgradebutton" @click="select(null)">Clear</button>
                    </div>   
                    <div class="horizFlex">
                        <smallbutton  @click="entered()" toplabel="Save" />
                        <smallbutton  @click="cancelupdate()" toplabel="Cancel"/>
                    </div>                  
                </div>

                <div class="centered" v-if="gradeType=='1to4'">
                    <div class="centered">
                        <input ref="numberinput1to4" class="gradeinput2" v-model="enteredGrade" v-on:keyup.enter="entered()">
                    </div> 
                    <div class="gradegrid">
                        <button class="selectgradebutton" @click="select(1)">1</button>
                        <p class="gradelabel">Needs Significant Training/Practice</p>
                        <button class="selectgradebutton" @click="select(2)">2</button>
                        <p class="gradelabel">Needs More Training/Practice</p>
                        <button class="selectgradebutton" @click="select(3)">3</button>
                        <p class="gradelabel">Is Getting Close to Being Proficient</p>
                        <button class="selectgradebutton" @click="select(4)">4</button>
                        <p class="gradelabel">Has Reached Proficiency Level at this Procedure</p>
                        <button class="selectgradebutton" @click="select('NA')" v-if="AllowNAAsGrade">NA</button>
                        <br>
                        <button class="selectgradebutton" @click="select(null)">Clear</button>
                    </div> 
                    <div class="horizFlex">
                        <smallbutton  @click="entered()" toplabel="Save" />
                        <smallbutton  @click="cancelupdate()" toplabel="Cancel"/>
                    </div>                    
                </div>
                
                <!-- accept gradedefpf for backwards compatibility -->
                <div class="centered" v-if="gradeType=='pf' || gradeType=='gradedefpf'">
                    <div class="gradegrid">
                        <button class="selectgradebutton" @click="select('P')">Pass</button>
                        <p class="gradelabel">OK</p>
                        <button class="selectgradebutton" @click="select('F')">Fail</button>
                        <p class="gradelabel">Needs Improvement</p>
                        <button class="selectgradebutton" @click="select('IA')" v-if="AllowIAAsGrade">IA</button>
                        <p class="gradelabel" v-if="AllowIAAsGrade">Instructor Assisted</p>
                        <button class="selectgradebutton" @click="select('NA')" v-if="AllowNAAsGrade">NA</button>
                        <br>
                        <button class="selectgradebutton" @click="select(null)">Clear</button>
                    
                    </div>                  
                </div>
                
                <div class="centered" v-if="gradeType=='enter'">
                    <div class="centered">
                        <input ref="numberinput" class="gradeinput2" v-model="enteredGrade" v-on:keyup.enter="entered()">
                    </div>  
                    <div class="keypad">
                        <button class="selectgradebutton" @click="enter('7')">7</button>
                        <button class="selectgradebutton" @click="enter('8')">8</button>
                        <button class="selectgradebutton" @click="enter('9')">9</button>
                        <button class="selectgradebutton" @click="enter('4')">4</button>
                        <button class="selectgradebutton" @click="enter('5')">5</button>
                        <button class="selectgradebutton" @click="enter('6')">6</button>
                        <button class="selectgradebutton" @click="enter('1')">1</button>
                        <button class="selectgradebutton" @click="enter('2')">2</button>
                        <button class="selectgradebutton" @click="enter('3')">3</button>
                        
                        <button class="selectgradebutton" @click="enter('0')">0</button>
                        <button class="selectgradebutton" @click="clear()">Clear</button>
                    </div>
                    <div class="horizFlex">
                        <smallbutton  @click="entered()" toplabel="Save" />
                        <smallbutton  @click="cancelupdate()" toplabel="Cancel"/>
                    </div>   
                    <p class="responsemessage">{{ responseMessage }}</p>             
                </div>
                

             
            </template>
          </actionmodalnarrow>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import datamethods from '../../services/datamethods.js'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import actionmodalnarrow from '../controls/actionmodalnarrow.vue'
   
    export default {
        name:'grade15',
        components:{
            smallbutton,
            actionmodalnarrow,

        },
        props:{
            gradeType:{
                type:String,
                default:"1to5"
            },
           
    
        },
        mixins:[datamethods,format],
        data(){
            return{
                enteredGrade:'',
                responseMessage:null
            }
        },
        computed:{
            ...mapFields([
               
            ]),
            AllowNAAsGrade(){
                if(window.ALLOW_NA_AS_GRADE==undefined){return false}
                else{return window.ALLOW_NA_AS_GRADE}
            },
            AllowIAAsGrade(){
                if(window.ALLOW_IA_AS_GRADE==undefined){return false}
                else{return window.ALLOW_IA_AS_GRADE}
            },
            
        },
        methods:{
           clear(){
            this.enteredGrade=""
            this.responseMessage=null
           },
           Update(){
            this.enteredGrade=''
            this.responseMessage=null
            this.$refs.mymodal.openModal()
            this.$nextTick(()=>{
                if(this.gradeType=='1to5'){
                    this.$refs.numberinput1to5.focus()
                }else if(this.gradeType=='1to4'){
                    this.$refs.numberinput1to4.focus()
                }else if(this.gradeType=='enter'){
                    this.$refs.numberinput.focus()
                }
                console.log('focus')
                
            })
           },
           enter(digit){
            if(this.isTextSelected(this.$refs.numberinput)){
                this.enteredGrade=digit
            }else{
                this.enteredGrade= this.enteredGrade + digit
            }
                
           },
           isTextSelected(input) {
                if (typeof input.selectionStart == "number") {
                    return input.selectionStart == 0 && input.selectionEnd == input.value.length;
                } else if (typeof document.selection != "undefined") {
                    input.focus();
                    return document.selection.createRange().text == input.value;
                }
           },

            cancelupdate(){
                this.$refs.mymodal.closeModal()
            
            },
            async select(grade){
              //  console.log('selectgrade')
                this.$emit('select', grade)
                this.$refs.mymodal.closeModal()
                   
            },
            entered(){
                if(this.enteredGrade>100){
                    this.responseMessage='Please enter a grade less than or equal to 100'
                    return;
                }
                this.$emit('select', this.enteredGrade)
                this.$refs.mymodal.closeModal()

            }
        },
        mounted(){
            
        }
    }
    </script>
    
    <style>
    .gradegrid{
        display:grid;
        grid-template-columns: 1fr 5fr;
        text-align:left;
    }
    .selectgradebutton{
    height:70px;
    width:70px;
    background-color: var(--frame-color);
    border:1px solid gray;
    font-size:larger;
    margin:5px;
  }
  .gradelabel{
    margin: 25px 0px 0px 10px;
  }
  .gradeinput2{
    text-align: center;
    width:100px;

  }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    