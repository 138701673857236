<template>
    <div class="loginDiv">
          <div >
          <form ref="loginform" v-on:keyup.enter="login">
              <div v-if="!useADSecurity">
                <formentrystacked inputid="user" label="email" v-model="username" />
                <formentrystacked inputid="pass" inputtype="password" label="password" v-model="password" />
                <smallbutton @click="login()" toplabel="Login"/>
              </div>
              
              
              <div v-if="useADSecurity">
                <smallbutton @click="startloginmsal()" toplabel="Login with Active Directory"/>
              </div>
              

              <div v-if="pleaseWait">
                  <label for="" class="pleasewaitlabel">Please Wait...</label>
              </div> 
          </form>
          </div>
            <linkbutton @click="forgotpassword()" toplabel="forgot password"/>
          <div>
              <label class="responsemessage">{{responseMessage}}</label>
          </div>
  
         <updatepassword ref="changepass" v-show="ischangepassword" :username="username" :oldpassword="oldpassword"></updatepassword> 
      </div>
  
          
  </template>
  
  <script>
  import {mapFields} from 'vuex-map-fields'
  import datamethods from '../../services/datamethods.js'
  import securityhelper from '../../services/securityhelper'
  import formentrystacked from '../controls/entry-stacked.vue'
  import linkbutton from '../controls/linkbutton.vue'
  import smallbutton from '../controls/button.vue'
  import modal from '../controls/modal.vue'
  import adhelper from '../../services/adhelper'
import updatepassword from './updatepassword.vue'

  /* import { msalInstance } from './msal'
  import {AuthError} from "@azure/msal-browser" */
  // import updatepassword from './actions/updatepassword.vue'
  export default {
      name:'loginuser',
      mixins:[datamethods,securityhelper,adhelper],
      components:{
          formentrystacked,
          linkbutton,
          smallbutton,
          modal,
          updatepassword
          
      },
      
      computed:{
          ...mapFields([
              'pleaseWaitText',
              'isAdminUser',
              'loggedInUser',
              'loggedIn',
              'currentWallet',
              'username',
              'oldpassword',
              'currentNav',
              'password'
  
          ]),
          useADSecurity(){
            if(window.USE_AD_SECURITY==undefined){return false}
            else{return window.USE_AD_SECURITY}
          }
      },
      data(){
          return{
              
              newUserURL:window.NEW_USER_URL,
              newUserText:'Click here to create a new Account',
  
              pleaseWait:false,
              responseMessage:'',
  
              ischangepassword:false,

              
          }
      },
      methods:{
        startloginmsal(){
            var logged = this.loginmsal()
            if(logged){this.securitylogin()}
        },

          changepassword(){
              this.ischangepassword=true
              this.$refs.changepass.ChangePassword();
          },
          async forgotpassword(){
              
               //validate
            if(this.username==""){
                return this.getFailureResponse("Please enter your email address and click forgot password")
              }
            let req = {
                email:this.username,
                
            }
            this.pleaseWait=true;
            this.responseMessage='';
            var response = await this.callAPI(req,'forgotpass_appurl')
            //handle response
            if(response.Successful){
                 this.responseMessage = "Please check your email for your password reset link"
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;    
          },
          async login(){
             
            this.securitylogin()
              
          },
         async post_login(token){
             
            this.securitypost_login(token)
                  
          },
  
  
        
      }
  }
  </script>
  
  <style>
  .loginDiv{
    margin-top:60px;
    grid-area: center;
    display:flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size:large;
  }
  .margin10{
      margin:10px;
  }
  </style>
  
  
   