<template>
    <div>
        <!-- <linkbutton toplabel="Update person" @click="UpdatePerson()"/> -->
        <!-- <smallbutton @click="Update()" :toplabel="updateText" :class="buttonclass"/> -->
        <!-- ACCT NOTE MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <label class="titlelabel">Add Comment</label>
                <div class="centered" >
                    
                    
                    <select name="" id="" class='entrySelect' required v-if="useCommentList" v-model="commentId" @change="selectComment()">
                        <option v-for="c in commentList" :key="c.id" :value="c.id" >
                            {{c.name}}
                        </option>
                    </select>
                    <textarea class="gradeinput" v-model="enteredComment" v-if="showCommentText"></textarea>
                    <div >
                        <smallbutton  @click="save()" toplabel="Save" />
                        <smallbutton  @click="cancelupdate()" toplabel="Cancel"/>
                    </div>                
                </div>
                
                <modalmessage ref="modalmessage" title="OFFLINE" body="This action is unavailable Offline."/>
             
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import datamethods from '../../services/datamethods.js'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import modalmessage from '../controls/modalmessage.vue'
    export default {
        name:'addcomment',
        components:{
            smallbutton,
            actionmodal,
            modalmessage
        },
        props:{
            existingComment:{
                type:String
            },
           
    
        },
        mixins:[datamethods,format],
        data(){
            return{
                enteredComment:'',
                commentId:-1,
                isCustom:false
            }
        },
        computed:{
            ...mapFields([
                'commentList',
                'online'
            ]),
            useCommentList(){
                if(window.USE_COMMENT_LIST==undefined){return false}
                else{return window.USE_COMMENT_LIST}
            },
            showCommentText(){
                return !this.useCommentList || this.isCustom
            }
        },
        methods:{
           selectComment(){
            var comment=null
            var id = this.commentId
            this.commentList.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(inst.id==id){
                        comment=inst.name
                    }
                }
            console.log('selcomment')
            if(comment=='Custom'){
                this.isCustom=true
            }else{
                this.isCustom=false
                this.enteredComment=comment
            }
            
           },
           Update(){
            this.commentId=-1
            this.enteredComment=''
            this.isCustom=false
            this.$refs.mymodal.openModal()
           },
           
            cancelupdate(){
                this.$refs.mymodal.closeModal()
            
            },
          
            save(){
                if(!this.online){this.handleOffline(); return}
                var comment 
                if(this.existingComment!=null){comment= this.existingComment + ' | ' + this.enteredComment}
                else{comment=this.enteredComment}
                
                this.$emit('save', comment)
                this.$refs.mymodal.closeModal()

            },
            //offline
            handleOffline(){
                this.$refs.modalmessage.show()
                //console.log('offline... no soup for you')
            },
           
        }
    }
    </script>
    
    <style>
    .gradegrid{
        display:grid;
        grid-template-columns: 1fr 5fr;
        text-align:left;
    }
    .selectgradebutton{
    height:70px;
    width:70px;
    background-color: var(--frame-color);
    border:1px solid gray;
    font-size:larger;
    margin:5px;
  }
  .gradelabel{
    margin: 25px 0px 0px 10px;
  }
  .gradeinput{
    text-align: left;
    width:500px;

  }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    