<template>
    <div>
        <p class="gradeboxlabel" v-if="!usingChecks" >{{ grade }}</p>
        <img :src="checkImage"  class="gradecheckimage" v-if="showCheck">
    </div>
</template>

<script>
export default {
    name:'flashers',
    props:{
        
        usingChecks:{
            type:Boolean,
    
        },
        showCheck:{
            type:Boolean,
           
        },
        grade:{
           type:String,
           
        }
        
    },
    watch:{
       
    },
    data() {
        return {
            
        };
    },
    methods:{
        
    },
    computed:{
        checkImage(){
            return require("@/assets/check.png")
        },

    }
}
</script>

<style scoped>
.gradecheckimage{
    height:20px;
  }
  .gradeboxlabel{
    margin:0px;
  }
</style>