<template>
    <div>
        <!-- <linkbutton toplabel="Update person" @click="UpdatePerson()"/> -->
        <!-- <smallbutton @click="Update()" :toplabel="updateText" :class="buttonclass"/> -->
        <!-- ACCT NOTE MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <label class="titlelabel">Time Review</label>
                <div class="timerecord">
                    <p>In Time</p>
                    <p>Out Time</p>
                    <p>Hours</p>
                </div>
                <div class="leftjustified" >
                    <div v-for="c in timeReviewData" :key="c.ID" :value="c.ID" class="" >
                        <p v-if="c.EmployeeTimeItems.length>0">{{c.EmployeeTimeItems[0].EmployeeName}} - {{c.EmployeeTimeItems[0].JobName}}</p>
                        <div v-for="t in c.EmployeeTimeItems" :key="t.ID" :value="t.ID" class="timerecord">
                            <input type="time" v-model="t.ActualIn" class="margin10" @change="updatetotal(t)"/>
                            <input type="time" v-model="t.ActualOut" class="margin10"  @change="updatetotal(t)"/>
                          
                            <p class="rightjustified">{{ formathours(t) }}</p>
                        </div>
                    </div>
                    
                    
                                  
                </div>
                <div >
                        <smallbutton  @click="save()" toplabel="Save" />
                        
                </div>  

             
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import datamethods from '../../services/datamethods.js'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import actionmodal from '../controls/actionmodal.vue'
   
    export default {
        name:'timereview',
        components:{
            smallbutton,
            actionmodal,

        },
        props:{
            
           
    
        },
        mixins:[datamethods,format],
        data(){
            return{
                enteredComment:'',
                allTotal:''
            }
        },
        computed:{
            ...mapFields([
               'timeReviewData'
            ]),
           
        },
        methods:{
            updatetotal(time){
                time.IsDirty=true
            },
            
           formathours(time){
            //return time.toFixed(2);
            var mytotal = 0
            var hrs
            var totalminutes;
                
                //get dates and times
                var startdate = this.$options.filters.formatDate(time.ClockInTime)
                var starttime = time.ActualIn
                var enddate = this.$options.filters.formatDate(time.ClockInTime)
                var endtime = time.ActualOut
                console.log(startdate + ' ' + starttime + ' ' + enddate + ' ' + endtime)
                //get current total
              //  var oldtotal = totlbl.text()

               

                var d2 = Date.parse(enddate + ' ' + endtime)
                var d1 = Date.parse(startdate + ' ' + starttime)
                var ms = d2 - d1
                console.log(ms)

                ms=    (ms / (1000 * 60));
                console.log(ms)

                var hrs  = ~~(ms / 60)
                console.log(hrs)
                var thisms  = ms - (hrs * 60)
                console.log(thisms)
                //totalminutes = totalminutes + thisms


                mytotal = hrs + ' hr ' + thisms + ' min'

                //get the all lbl
                //***thi  needs to be by st id? */
               // var oldall = this.allTotal

                //fix its all total
                //var newall = parseFloat(oldall) - parseFloat(oldtotal) + parseFloat(hrs)
              //  varnewall = getMinutes(oldall) - getMinutes(oldtotal) + ms
               // var allhrs = ~~(varnewall / 60)
               // var allms = varnewall - (allhrs * 60)
               // newall = allhrs + ' hr ' + allms + ' min'//newall.toFixed(2);
               // this.allTotal=newall

                return mytotal

           },
           Start(){
            var filters = this.$options.filters
            this.timeReviewData.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                comp.EmployeeTimeItems.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp2=arr[index]

                    var t= new Date(comp2.ActualIn);
                    var hh=String(t.getHours()).padStart(2, '0');
                    var nn= String(t.getMinutes()).padStart(2, '0');
                    //var ss=String(t.getSeconds()).padStart(2, '0');
                    comp2.ActualIn= hh+ ':' + nn 

                     var t2= new Date(comp2.ActualOut);
                    var hh2=String(t2.getHours()).padStart(2, '0');
                    var nn2= String(t2.getMinutes()).padStart(2, '0');
                    //var ss2=String(t2.getSeconds()).padStart(2, '0');
                    comp2.ActualOut= hh2+ ':' + nn2 
                }
                
            }
            
            this.$refs.mymodal.openModal()
           },
           
            
          
            async save(){
                let req = {
                    timeReviewData:this.timeReviewData
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'savetimereview')
                //handle response
                if(response.Successful){
                    this.pleaseWait=false; 
                    this.$emit('close')
                    this.$refs.mymodal.closeModal()
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 

                

            }
        }
    }
    </script>
    
    <style>
    .gradegrid{
        display:grid;
        grid-template-columns: 1fr 5fr;
        text-align:left;
    }
    .selectgradebutton{
    height:70px;
    width:70px;
    background-color: var(--frame-color);
    border:1px solid gray;
    font-size:larger;
    margin:5px;
  }
  .gradelabel{
    margin: 25px 0px 0px 10px;
  }
  .gradeinput{
    text-align: left;
    width:500px;

  }
  .timerecord{
    display:grid;
    grid-template-columns: 2fr 2fr 1fr;
  }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    