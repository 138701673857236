<template>
    <div>
        <smallbutton @click="Start()" toplabel="End Training" :buttonclass="buttonclass"/>
        <!-- ACCT NOTE MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <label class="titlelabel">Clock Out Students</label>
                <br>
                <label class="entryLabel" for="">Select Location</label>
                <optionbutton :itemList="locationList" @select="selectLocation" :initialSelectedId="locationId"/>
             
                
                
                <div class="leftjustified" v-if="locationId>0">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Select Students</label>
                        <button @click="selectAll" class="left5">Select All</button>
                        <button @click="clearAll" class="left5">Clear All</button>
                    </div>
                    <div class="horizFlex wrap">
                        <div v-for="s in studentList" :key="s.id" :value="s.id" class="checkrecord">
                            
                                <input class="" type="checkbox" v-model="s.selected" >
                                <p class="required" v-if="s.HasInProcessGradeSheet">*</p>
                                <p :class="studentchecklabel(s)">{{s.name}}</p>
                            
                            
                        </div>
                    </div>
                    
                </div>
                
           
                <div>

                    <smallbutton  @click="save()" toplabel="Clock Out" buttonclass="buttonprimary extrawidebutton"/>
                    <smallbutton  @click="cancel()" toplabel="Cancel" buttonclass="buttonprimary widebutton"/>
                </div>
                <p class="responsemessage">{{ responseMessage }}</p>
                <div  v-if="areUnsubGrades">
                    <label class="responsemessage">*NOTE </label>
                    <label>Red Students have unsubmitted grade sheets.  Please submit those grade sheets before Clocking Out the students. </label>
                </div>
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import datamethods from '../../services/datamethods.js'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import optionbutton from '../controls/optionbutton.vue'
    import securityhelper from '../../services/securityhelper.js'
    export default {
        name:'clockout',
        components:{
            smallbutton,
            actionmodal,
            optionbutton
        },
        props:{
            buttonclass:{
                type:String,
                default:"button buttonprimary"
            },
           
    
        },
        mixins:[datamethods,format,securityhelper],
        data(){
            return{
                locationId:0,
                studentList:[],
                areUnsubGrades:false
            }
        },
        computed:{
            ...mapFields([
                'locationList',
                 'loggedInUser',
                 'timeReviewData',
                 'online',
                 'gradeList'
            ]),
            studentSelected(){
                var sel=false
                this.studentList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    if(arr[index].selected){
                        sel=true
                    }
                }
                return sel
           }
        },
        methods:{
            selectAll(){
                this.studentList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    arr[index].selected=true
                }
            },
            clearAll(){
                this.studentList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    arr[index].selected=false
                }
            },
            studentchecklabel(stu){
               // return 'checklabel'
                if(stu.HasInProcessGradeSheet){return "checklabel required"}
                else{return "checklabel"}
            },
            selectLocation(id){
                this.locationId=id
                this.getStudents();
        
            },
           Start(){
            if(!this.online){this.$emit('offline'); return;}

            //this.checkForOpenGrades()

                this.locationId=this.loggedInUser.LocationId
                this.selectLocation(this.locationId);
                this.$refs.mymodal.openModal()
           },
           
            cancel(){
                this.$refs.mymodal.closeModal()
                this.$emit('refresh')
            },
           /*  checkForOpenGrades(){
                this.openGradeList=null
                console.log('check for open grades')
                var open=null
                var scope = this
                this.gradeList.forEach(myFunction2)
                 function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(!inst.IsLocked){
                        var stId = inst.StudentID
                        //console.log('looking at stu ' + stId)
                        scope.studentList.forEach(myFunction3)
                            function myFunction3(item,index, arr){
                                let stu=arr[index]
                                if(stu.id==stId){
                                    console.log('student with open grade! ' + stu.name)
                                    arr[index].hasOpenGrade=true
                                    //if(open==null){open = stu.name}
                                    //else{open = open + ', ' + stu.name}
                                }
                            }
                    }
                }
                this.openGradeList=open
            }, */

            async save(){
                if(!this.studentSelected){this.responseMessage="Please select at least one student"; return}

               //clear time review data
               this.timeReviewData=[]
                let req = {
                    locationId:this.locationId,
                    instructorId:this.loggedInUser.user_personId,
                    studentList:this.studentList
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'clockoutstudentlist')
                //handle response
                if(response.Successful){
                    this.timeReviewData = response.timeReviewData
                    this.pleaseWait=false; 
                    this.$emit('refresh')
                    this.$refs.mymodal.closeModal()
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
                   
            },
            async getStudents(){
                let req = {
                    locationId:this.locationId,
                    RestrictToNotClockedInNow:false,
                    RestrictToClockedInNow:true,
                    personId_ClockedInBy:this.loggedInUser.user_personId
                }
                //if we have access to clock out all, then change personidclockedinby to -1
                if(this.canAccess('Clockout Students Other Instructor')){
                    req.personId_ClockedInBy=-1
                }

                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'studentsforinout')
                //handle response
                if(response.Successful){
                    this.studentList=response.PersonList
                    //see if we have any unsubs
                    var unsub = false
                    this.studentList.forEach(myFunction3)
                            function myFunction3(item,index, arr){
                                let stu=arr[index]
                                if( stu.HasInProcessGradeSheet){
                                    unsub=true
                                }
                            }
                    this.areUnsubGrades=unsub

                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
          },
        }
    }
    </script>
    
    <style>
    .checkrecord{
        display:flex;
        flex-direction: row;
        margin: 0px 0px 0px 20px;
    }
    .gradegrid{
        display:grid;
        grid-template-columns: 1fr 5fr;
        text-align:left;
    }
    
    .updatebutton{
        height:30px;
        width:150px;
        border-radius: 5px;
        padding:3px;
        margin:0px 0px 10px 0px;
    }
    .checklabel{
        margin:5px;
    }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    